import React from 'react';
import logo from './logo.svg';
import { MediaPlayer, MediaPlayerSettingClass } from 'dashjs';
import Constants from 'dashjs/src/streaming/constants/Constants';
import './App.css';

const player = MediaPlayer().create();

class PlayerSettings {
  streaming: {
    stableBufferTime: number;
    bufferTimeAtTopQualityLongForm: number;
    abr: {
      minBitRate: {
        video: number;
      }
      maxBitRate: {
        video: number;
      }
    }
    limitBitRateByPortal: boolean;
  };

  constructor() {
    this.streaming = {
      stableBufferTime: 10,
      bufferTimeAtTopQualityLongForm: 10,
      abr: {
        minBitRate: {
          video: 0,
        },
        maxBitRate: {
          video: 0,
        },
      },
      limitBitRateByPortal: false,
    };
  }
};

function App() {
  const [bufferTime, setBufferTime] = React.useState<string>('10');
  const [manifestURL, setManifestURL] = React.useState<string>('https://dash.akamaized.net/akamai/bbb_30fps/bbb_30fps.mpd');
  const [minDrift, setMinDrift] = React.useState<string>('0.02');
  const [maxDrift, setMaxDrift] = React.useState<string>('0.00');
  const [playbackRate, setPlaybackRate] = React.useState<string>('0.5');
  const [playerSettings, setPlayerSettings] = React.useState<MediaPlayerSettingClass>({
    streaming: {
      stableBufferTime: parseInt(bufferTime, 10),
      liveCatchup: {
        minDrift: 0.02,
        maxDrift: 0,
        playbackRate: 0.5,
        latencyThreshold: undefined,
        playbackBufferMin: undefined,
        enabled: true,
        mode: Constants.LIVE_CATCHUP_MODE_DEFAULT,
      }
    }
  });
  const videoRef = React.createRef<HTMLVideoElement>();

  

  React.useEffect(() => {
    const loadDashPlayer = (url: string) => {
      const videoEl = videoRef.current;
      console.log('url: ', url);
      if (!videoEl) {
        return;
      }
      player.initialize(videoEl, url, false);
      player.updateSettings(playerSettings);
      console.log('tried to load the player ? ');
    };
    if (manifestURL.length <= 10) {
      return;
    }
    loadDashPlayer(manifestURL);
  }, [manifestURL]);

  React.useEffect(() => {
    player.updateSettings(playerSettings);
  }, [playerSettings]);

  const handleBufferTime = (ev: React.ChangeEvent<HTMLInputElement>) => {
    setBufferTime(ev.target.value);
    /* const newTime = parseFloat(ev.target.value);
    if (!newTime) {
      return;
    }
    const copy = { ...playerSettings };
    if (copy.streaming) {
      copy.streaming.stableBufferTime = newTime;
    }
    setPlayerSettings(copy);
    console.log('updated buffer time to ', newTime); */
  };

  const handleMinDrift = (ev: React.ChangeEvent<HTMLInputElement>) => {
    setMinDrift(ev.target.value);
  }
  const handleMaxDrift = (ev: React.ChangeEvent<HTMLInputElement>) => {
    setMaxDrift(ev.target.value);
  }
  const handlePlaybackRate = (ev: React.ChangeEvent<HTMLInputElement>) => {
    setPlaybackRate(ev.target.value);
  }

  const update = () => {
    const minD = parseFloat(minDrift);
    const maxD = parseFloat(maxDrift);
    const rate = parseFloat(playbackRate);
    const buffer = parseFloat(bufferTime);
    if (!minD || !maxD || !rate || !buffer) {
      return;
    }
    const copy = { ...playerSettings };
    if (!copy.streaming) {
      return;
    }
    copy.streaming.stableBufferTime = buffer;
    copy.streaming.liveCatchup.maxDrift = maxD;
    copy.streaming.liveCatchup.minDrift = minD;
    copy.streaming.liveCatchup.playbackRate = rate;
    setPlayerSettings(copy);
  };

  return (
    <div className="App">
      <div className="form">
        <input type="text" value={manifestURL} onChange={(ev) => setManifestURL(ev.target.value)} placeholder="DASH Manifest URL" />
        <label>Buffer time (seconds)</label>
        <input type="text" value={bufferTime} onChange={handleBufferTime} placeholder="Buffer size" />
        <label>Playback rate</label>
        <input type="text" value={playbackRate} onChange={handlePlaybackRate} placeholder="Playback rate" />
        <label>Min. drift</label>
        <input type="text" value={minDrift} onChange={handleMinDrift} placeholder="Min drift" />
        <label>Max. drift</label>
        <input type="text" value={maxDrift} onChange={handleMaxDrift} placeholder="Max drift" />
        <button onClick={() => update()}>Update</button>
      </div>
      <div className="video">
        <video ref={videoRef} controls />
      </div>
    </div>
  );
}

export default App;
